import React from 'react'
import { graphql } from 'gatsby'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import { mapEdgesToNodes, filterOutDocsWithoutSlugs, filterOutHiddenDocs } from '../lib/helpers'
import Artist from '../components/artist'
import ContentContainer from '../containers/content'

export const query = graphql`
  query CollaboratorTemplateQuery($id: String!) {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
      openGraph {
        image {
          ...SanityImage
        }
      }
    }
    artist: sanityCollaborator(id: { eq: $id }) {
      id
      title
      featuredShowsTitle
      links {
        title
        href
        download {
          asset {
            url
          }
        }
      }
      hideSlides
      hideFeaturedList
      hidePressList
      _rawAbout(resolveReferences: { maxDepth: 5 })
      _rawBio(resolveReferences: { maxDepth: 5 })
      _rawGallerySlides(resolveReferences: { maxDepth: 5 })
    }
    pressArticles: allSanityPressArticle(sort: { fields: [date], order: DESC }) {
      edges {
        node {
          id
          title
          publisher
          group
          date
          externalLink
          artists {
            title
          }
        }
      }
    }
    collaborations: allSanityCollaboration(
      sort: { fields: [endDate], order: DESC }
      filter: { slug: { current: { ne: null } }, isVisible: { eq: true } }
    ) {
      edges {
        node {
          id
          title
          artist
          location
          startDate
          endDate
          previewImageSize {
            widthDesktop
          }
          mainImage {
            ...SanityImage
            alt
          }
          tags {
            _id
            title
          }
          slug {
            current
          }
          artistList {
            title
            slug {
              current
            }
          }
          isVisible
          _rawFormattedTitle
          _rawGallerySlides(resolveReferences: { maxDepth: 5 })
        }
      }
    }
  }
`

const CollaboratorTemplate = props => {
  const { data, errors } = props
  const site = (data || {}).site
  const siteOgImage = site.openGraph.image
  const artist = data && data.artist
  const pressArticleNodes = (data || {}).pressArticles ? mapEdgesToNodes(data.pressArticles) : []
  const collaborationNodes = (data || {}).collaborations
    ? mapEdgesToNodes(data.collaborations)
        .filter(filterOutDocsWithoutSlugs)
        .filter(filterOutHiddenDocs)
    : []
  return (
    <ContentContainer section="collaborations" singleView>
      {errors && <SEO title="GraphQL Error" />}
      {artist && (
        <SEO
          title={artist.title || site.title}
          description={artist.metaDescription || site.description}
          keywords={site.keywords}
          image={siteOgImage}
        />
      )}
      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}
      {artist && (
        <Artist
          {...artist}
          pressArticleNodes={pressArticleNodes}
          collaborationNodes={collaborationNodes}
        />
      )}
    </ContentContainer>
  )
}

export default CollaboratorTemplate
